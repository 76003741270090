@import "../../../styles/helpers";
.container {
    position: relative;
    border-radius: 10px;
    width: 100%;
    text-align: center;
    padding: 10px 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    @include m {
        width: 90%;
        margin-left: 5%; } }
.text {
    color: $lightYellow;
    margin-bottom: 20px;
    font-size: 22px;
    font-family: cursive; }
.textStrong {
    color: $lightYellow;
    font-size: 25px;
    margin-bottom: 20px;
    font-family: cursive;
    text-decoration: underline; }
.textVal {
    color: white;
    font-size: 20px; }

.link {
    color: $yellow;
    margin-right: 20px;
    @include m {
        inline-size: 100px;
        overflow-wrap: break-word; } }
.row {
    display: flex;
    justify-content: space-between; }

.col {
    display: flex;
    justify-content: space-between;
    &:first-child {
        flex-group: 1;
        padding-right: 32px;
        @include w {
            width: auto;
            position: relative;
            margin-bottom: 32px;
            padding: 0; }
        @include d {
            width: auto;
            position: relative;
            margin-bottom: 32px;
            padding: 0; } }
    &:nth-child(2) {
        flex-shrink: 0;
        flex: 1;
        height: auto;
        @include w {
            flex: 1;
            height: auto;
            position: relative;
            margin-bottom: 32px;
            padding: 0 30px; }
        @include x {
            flex: 1;
            height: auto;
            position: relative;
            margin-bottom: 32px;
            padding: 0 30px; }

        @include d {
           flex: 1 {
            position: relative;
            margin-bottom: 32px;
            padding: 0; } } }
    &:nth-child(3) {
        width: auto;
        padding-left: 32px;
        @include w {
            width: auto;
            margin-bottom: 32px;
            padding: 0; }
        @include t {
            width: 100%;
            padding: 0; } } }
.donatesite {}

