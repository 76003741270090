@import "../../styles/helpers";

.form {
 }    // position: relative

.input {
    width: 100%;
    height: 48px;
    padding: 0 48px 0 14px;
    border-radius: 15px;
    background: none;
    border: 2px solid $darkBlue;
    @include poppins;
    @include caption-1;
    color: $neutrals2;
    transition: border-color .2s;
    @include dark {
        border-color: $darkBlue;
        color: $neutrals8; }
    &:focus {
        border-color: $blue; }
    @include placeholder {
        color: $neutrals2; } }

.textarea {
    width: 100%;
    margin-top: 10px;
    padding: 0 48px 0 14px;
    border-radius: 15px;
    background: none;
    border: 2px solid $darkBlue;
    @include poppins;
    @include caption-1;
    color: $neutrals2;
    transition: border-color .2s;
    @include dark {
        border-color: $darkBlue;
        color: $neutrals8; }
    &:focus {
        border-color: $blue; }
    @include placeholder {
        color: $neutrals2; } }

.btn {
    // position: absolute
    // top: 8px
    // right: 8px
    // bottom: 8px
    width: 100%;
    color: $white;
    height: 32px;
    border-radius: 15px;
    background: $blue;
    transition: background .2s;
    svg {
        fill: $neutrals8; }
    &:hover {
        background: darken($blue, 10); } }
