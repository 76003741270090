@import "../../../styles/helpers";
.relative {
    position: relative;
    width: 60%;
    height: 500px;
    @include w {
        height: 500px; }
    @include m {
        width: 90%;
        margin-left: 5%;
        height: 400px; } }
.mapBox {
    width: 100%;
    height: 100%; }
.mapContainer {
    height: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); }
.sidebar {
    background-color: rgba(35, 55, 75, 0.9);
    color: #fff;
    padding: 6px 12px;
    font-family: monospace;
    z-index: 1000;
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 12px;
    border-radius: 4px; }

.soldout {
    height: 70px;
    width: 400px;
    background-image: url("../../../assets/banner.png");
    // background-image: url("image.png")
    background-position: left;
    background-repeat: no-repeat;
    background-size: contain;
    // background-color: $yellow
    color: #ffa520;
    display: flex;
    padding: 12px 12px;
    font-family: monospace;
    z-index: 1;
    position: absolute;
    top: 10px;
    left: -23px;
    // margin: 12px
    border-radius: 4px;
    display: flex;
    justify-content: start;
    align-items: start;
    label {
        font-size: 20px;
        font-weight: 800;
        text-shadow: 2px 2px 4px #1c1509; } }


.marker {
    background-image: url("https:://localhost:3000/mapbox-icon.png");
    background-size: cover;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer; }

.buyBtn {
    background-color: 'green';
    border-radius: 10px;
    text-align: 'center';
    color: 'white'; }
