@import "../../../styles/helpers";
.newContainer {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    @include w {
        width: 100%;
        margin: 0px 20px; } }



.newsLoad {
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px; }


.container {
    border-radius: 10px;
    background-color: #3772ff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 300px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // flex: 1
    img {
        width: 100%;
        height: 300px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        object-fit: cover;
        @include w {
            width: 200px;
            border-radius: 10px; }

        @include d {
            width: 400px;
            border-radius: 10px; }

        @include t {

            border-radius: 10px;
            width: 350px; } }
    h4 {
        color: $yellow;
        text-align: center; }
    p {
        word-break: break-all;
        color: $white; }
    @include w {
        width: 200px;
        padding: 15px 10px; }
    @include d {
        width: 100%;
        padding: 15px 10px; }
    @include t {
        width: 100%;
        padding: 15px 10px; } }

.text {
    color: white;
    margin-bottom: 20px; }
.newsTitle {
    padding: 10px 15px; }
