@import "../../../styles/helpers";

.container {
    padding-top: 150px;
    padding-left: 30px; }
.col {
    display: flex;

    &:first-child {
        flex-group: 1;
        padding-right: 32px;
        @include w {
            width: auto;
            position: relative;
            margin-bottom: 32px;
            padding: 0; }
        @include d {
            width: auto;
            position: relative;
            margin-bottom: 32px;
            padding: 0; } }
    &:nth-child(2) {
        flex-shrink: 0;
        flex: 1;
        height: auto;
        @include w {
            flex: 1;
            height: auto;
            position: relative;
            margin-bottom: 32px;
            padding: 0 30px; }
        @include x {
            flex: 1;
            height: auto;
            position: relative;
            margin-bottom: 32px;
            padding: 0 30px; }

        @include d {
           flex: 1 {
            position: relative;
            margin-bottom: 32px;
            padding: 0; } } }
    &:nth-child(3) {
        width: auto;
        padding-left: 32px;
        @include w {
            width: auto;
            margin-bottom: 32px;
            padding: 0; }
        @include t {
            width: 100%;
            padding: 0; } } }
.row {
    display: flex;
    padding: 20px 32px;
    border-bottom: 1px solid $neutrals6;
    justify-content: space-between;
    @include d {
        display: block;
        padding: 64px 0 48px; }
    @include m {
        padding: 64px 0 48px; }
    @include dark {
        border-color: $neutrals3; } }
.mark {
    position: absolute; }
