@import "../../../styles/helpers";

.category {
    @include body-bold-2; }

.text {
    margin-top: 4px;
    @include caption-2;
    color: $neutrals4; }
.price {
    color: $green;
    word-break: break-word;
    font-style: italic; }
.active {
    border: 3px solid $yellow; }
.cards {
    display: flex;
    margin: 0px 0px 20px 0px;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    @include m {
        margin-top: 50px;
        display: block;
        text-align: center; }
    &::-webkit-scrollbar {
        display: none; }
    @include m {
        &:before,
        &:after {
            content: "";
            flex-shrink: 0;
            width: 32px;
            height: 1px; } } }

.card {
    flex-shrink: 0;
    width: 200px;
    margin: 0px 0px;
    margin-right: 30px;
    padding: 24px;
    border-radius: 16px;
    background: $darkBlueBack;
    @include button-2;
    cursor: pointer;
    @include m {
        margin: auto;
        margin-bottom: 8px; }
    @include dark {
        background: $darkBlueBack; }
    &:hover {
        .plus {
            svg {
                opacity: {} } } } }
.img {
    border-radius: 10px;
    object-fit: cover; }

.plus {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    // height: 32px
    border-radius: 50%;
    margin-bottom: 12px;
    svg {
        fill: $neutrals8;
        opacity: 0;
        transition: opacity .2s; } }

.subtitle {
    font-size: 18px; }
.subDesc {
    color: $neutrals5;
    font-weight: 300!important; }

.view_img {
    width: 300px;
    height: 300px;
    border-radius: 30px; }
