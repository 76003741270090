@import "../../styles/helpers";

.section {
    padding-top: 130px!important;
    @include x {
        padding-top: 40px!important; }
    @include m {
        padding-top: 30px!important; } }

.container {
    display: flex;
    align-items: flex-start;
    @include t {
        display: block; } }

.wrapper {
    flex: 0 0 calc(100% - 352px);
    width: calc(100% - 352px);
    padding-right: 128px;
    @include x {
        padding-right: 64px; }
    @include d {
        flex: 0 0 calc(100% - 304px);
        width: calc(100% - 304px);
        padding-right: 32px; }
    @include t {
        width: 100%;
        padding: 0; } }

.preview {
    flex-shrink: 0;
    width: 352px;
    @include d {
        width: 304px; }
    @include t {
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 48px;
        background: rgba($neutrals1,.9);
        overflow: auto;
        z-index: 999;
        visibility: hidden;
        opacity: 0;
        transition: all .4s;
        @include dark {
            background: rgba($neutrals2,.9); }
        &.active {
            visibility: visible;
            opacity: 1; } }
    @include m {
        padding: 32px 16px; } }

.head {
    display: flex;
    margin-bottom: 40px;
    @include d {
        flex-direction: column-reverse;
        align-items: flex-start; }
    @include m {
        margin-bottom: 32px; }
    .button {
        flex-shrink: 0;
        margin-left: 24px;
        @include d {
            margin: 0 0 16px; } } }

.title {
    margin-right: auto; }

.list {
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid $neutrals6;
    @include m {
        margin-bottom: 32px;
        padding-bottom: 32px; }
    @include dark {
        border-color: $neutrals6; } }

.item {
    &:not(:last-child) {
        margin-bottom: 40px;
        @include m {
            margin-bottom: 32px; } } }
.loading {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    @include m {
        padding-top: 180px; } }
.note {
    margin-top: 4px;
    @include caption-2;
    // color: $neutrals4
    color: $neutrals5; }

.file {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 182px;
    margin-top: 16px;
    border-radius: 16px;
    overflow: hidden;
    border-color: $yellow;
    border-width: 1px;
    // background: $neutrals7
    background:  $darkBlueBack;
    box-shadow: inset 0 0 0 1px $yellow;
    @include dark {
        // background: $neutrals2
        background: $darkBlueBack;
        box-shadow: inset 0 0 0 1px $yellow; } }

.load {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 400px;
    opacity: 0; }

.icon {
    margin-bottom: 10px;
    svg {
        fill: $neutrals4; } }

.format {
    @include caption-2;
    color: $neutrals4; }

.category {
    @include body-bold-2; }

.fieldset {
    margin-top: 32px;
    .field {
        &:not(:last-child) {
            margin-bottom: 32px;
            @include m {
                margin-bottom: 20px; } } } }

.label {
    margin-bottom: 12px;
    @include hairline-2;
    color: $neutrals5; }

.row {
    display: flex;
    margin: 0 -10px;
    @include m {
        display: block;
        margin: 0; } }

.col {
    flex: 0 0 calc(33.333% - 20px);
    width: calc(33.333% - 20px);
    margin: 0 10px;
    @include m {
        width: 100%;
        margin: 0;
        &:not(:last-child) {
            margin-bottom: 20px; } } }

.options {
    margin-bottom: 40px;
    @include m {
        margin-bottom: 32px; } }

.option {
    display: flex;
    align-items: flex-start;
    &:not(:last-child) {
        margin-bottom: 32px; } }

.box {
    flex-grow: 1; }

.switch {
    flex-shrink: 0;
    margin-left: 24px; }

.text {
    margin-top: 4px;
    @include caption-2;
    color: $neutrals5; }
.imgFit {
    object-fit: contain; }

.foot {
    display: flex;
    align-items: center;
    @include m {
        display: block;
        text-align: center; }
    .button {
        @include m {
            width: 100%; }
        &:not(:last-child) {
            margin-right: 24px;
            @include m {
                margin: 0 0 12px; } } } }

.saving {
    display: inline-flex;
    align-items: center;
    margin-left: auto;
    @include button-1; }

.loader {
    margin-left: 24px; }

.collect {
    font-size: 14px;
    margin-top: 20px;
    border: 2px solid #E6E8EC;
    border-radius: 10px;
    display: flex;
    text-indent: 10px;
    padding: 10px 0px 10px 0px; }

.collectDrop {
    color: black;
    background-color: white;
    border-radius: 15px !important;
    text-indent: 15px; }
.setVal {
    display: flex;
    width: 100%;
    @include m {
        display: block;
        width: 100%; } }
