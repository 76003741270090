@import "../../../styles/helpers";
.container {
    position: relative;
    border-radius: 10px;


    padding: 10px 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    @include w {
        margin: 0px 20px;
        flex: 1; }
    @include t {
        margin: 0px 20px;
        flex: 1; } }
.text {
    color: $lightYellow;
    margin-bottom: 20px;
    font-size: 20px;
    font-family: cursive; }

.textStrong {
    color: $lightYellow;
    font-size: 25px;
    margin-bottom: 20px;
    font-family: cursive;
    text-decoration: underline; }
