.view_img {
    width: 100%;
    height: 100px;
    border-radius: 10px; }

.MainCollect {
    display: flex;
    flex-direction: column;
    row-gap: 10px; }
.ImgPart {
    display: flex;
    flex-direction: column;
    row-gap: 10px; }
.cNameInput {
    border: 1px solid #E6E8EC;
    font-size: 15px;
    padding: 5px;
    text-indent: 10px;
    border-radius: 10px; }

.NamePart {
    display: flex;
    flex-direction: column;
    row-gap: 10px; }
.cDesInput {
    height: 120px;
    border: 1px solid #E6E8EC;
    font-size: 15px;
    padding: 5px;
    text-indent: 10px;
    border-radius: 10px; }
.sPart {
    width: 100%;
    display: flex;
    justify-content: center; }
.sButton {
    background-color: rgb(55, 114, 255);
    color: white;
    width: 200px;
    font-size: 20px;
    padding: 8px 0;
    border-radius: 10px; }
