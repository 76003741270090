@import "../../styles/helpers";


.row {
    display: flex;
    padding: 20px 32px;
    border-bottom: 1px solid $neutrals6;
    justify-content: space-between;
    @include d {
        display: block;
        padding: 64px 0 48px; }
    @include m {
        padding: 64px 0 48px; }
    @include dark {
        border-color: $neutrals3; } }

.col {
    display: flex;
    justify-content: space-between;
    &:first-child {
        flex-group: 1;
        padding-right: 32px;
        @include w {
            width: auto;
            position: relative;
            margin-bottom: 32px;
            padding: 0; }
        @include d {
            width: auto;
            position: relative;
            margin-bottom: 32px;
            padding: 0; } }
    &:nth-child(2) {
        flex-shrink: 0;
        flex: 1;
        height: auto;
        @include w {
            flex: 1;
            height: auto;
            position: relative;
            margin-bottom: 32px;
            padding: 0 30px; }
        @include x {
            flex: 1;
            height: auto;
            position: relative;
            margin-bottom: 32px;
            padding: 0 30px; }

        @include d {
           flex: 1 {
            position: relative;
            margin-bottom: 32px;
            padding: 0; } } }
    &:nth-child(3) {
        width: auto;
        padding-left: 32px;
        @include w {
            width: auto;
            margin-bottom: 32px;
            padding: 0; }
        @include t {
            width: 100%;
            padding: 0; } } }
.container {
    background-color: grey; }



.map {
    height: 100%; }


.desc2 {
    padding: 20px 32px;
    background-color: $yellow;
    p {
        color: $darkBlue;
        text-align: center;
        @include body-bold-1; } }

.donateContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    p {
        color: $yellow;
        margin-right: 20px;
        font-size: 20px;
        font-weight: 600; } }
.button {
    border-color: $yellow!important; }

.donatesite {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 32px;
    p {
        color: $yellow;
        margin-left: 20px; } }

.link {
    color: $yellow;
    margin-right: 20px; }
.videoTag {
    width: 100%;
    position: fixed;
    filter: grayscale(1) blur(2px) brightness(0.5);
    @include m {
        width: 100%;
        height: 100%;
        position: fixed;
        filter: grayscale(1) blur(2px) brightness(0.5); } }
.mapVal {}

.instructionVal {
    padding-top: 120px; }
.helpImage {
    position: relative;
    margin-top: 120px;
    height: 350px;
    border-radius: 15px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    object-fit: contain;
    @include m {
        width: 90%;
        margin-left: 5%; } }

.helpImageMap {
    position: relative;
    margin-top: 120px;
    height: 350px;
    border-radius: 15px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    object-fit: contain;
    @include m {
        margin-left: 5%;
        width: 90%;
        height: 100%; } }
