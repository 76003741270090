@import "../../styles/helpers";
$gradient: $yellow, $lightYellow , $yellow;
.footer {
    position: relative;
    border-top: 1px solid $darkBlue;
    // background: -webkit-linear-gradient($gradient)
    // background: -moz-linear-gradient($gradient)
    // background: -o-linear-gradient($gradient)
    // background: linear-gradient($gradient)
    background-color: $yellow;
    @include dark {
        background-color: $yellow;
        // background: -webkit-linear-gradient($gradient)
        // background: -moz-linear-gradient($gradient)
        // background: -o-linear-gradient($gradient)
 } }        // background: linear-gradient($gradient)

.row {
    display: flex;
    padding: 80px 0 48px;
    border-bottom: 1px solid $darkBlue;
    @include t {
        display: block; }
    @include m {
        padding: 64px 0 48px; }
    @include dark {
        border-color: $darkBlue; } }

.col {
    &:first-child {
        flex-grow: 1;
        padding-right: 32px;
        @include t {
            position: relative;
            margin-bottom: 32px;
            padding: 0; } }
    &:nth-child(2) {
        display: flex;
        flex-shrink: 0;
        width: 352px;
        @include d {
            width: 276px; }
        @include t {
            width: auto;
            margin-bottom: 32px; }
        @include m {
            display: block;
            border-width: 1px 0;
            border-style: solid;
            border-color: $darkBlue;
            @include dark {
                border-color: $darkBlue; } } }
    &:nth-child(3) {
        flex-shrink: 0;
        width: 384px;
        padding-left: 32px;
        @include d {
            width: 304px; }
        @include t {
            width: 100%;
            padding: 0; } } }

.logo {
    display: inline-block;
    margin-bottom: 32px;
    img {
        width: 128px; } }

.info {
    max-width: 256px;
    color: $darkBlue;
    @include body-1;
    @include d {
        font-size: 16px; } }

.version {
    display: flex;
    align-items: center;
    margin-top: 24px;
    @include t {
        position: absolute;
        top: 5px;
        right: 0;
        margin: 0; } }

.details {
    margin-right: 16px;
    color: $darkBlue;
    @include caption-2;

    @include m {
        margin-right: 10px; } }

.category {
    margin-bottom: 40px;
    display: flex;
    justify-content: start;
    color: $darkBlue;
    @include body-bold-2;
    // +t
 }    //  margin-block-end: 24px

.text {
    margin-left: 20px;
    color: $darkBlue; }

.foot {
    display: flex;
    justify-content: space-between;
    padding: 32px 0;
    @include caption-2;
    @include m {
        display: block;
        padding: 24px 0 32px; } }

.copyright {
    color: $darkBlue;
    @include m {
        text-align: center; } }

.note {
    color: $darkBlue;
    @include m {
        display: none; }
    a {
        margin-left: 16px;
        font-weight: 600;
        color: $darkBlue;
        transition: color .2s;
        &:hover {
            color: darken($blue, 10); } }
    @include dark {
        color: $darkBlue; } }
.social {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    border: 2px solid $darkBlue;
    padding: 5px;
    margin-right: 20px;
    color: $darkBlue;
    &:hover {
        padding: 8px;
        border-color: $blue-light; } }

.socialIcon {
    width: 20px;
    height: 16px;
    color: $darkBlue;
    fill: $darkBlue;
    &:hover {
        fill: $blue-light; } }
// .form
//     background: none
//     border: 2px solid $darkBlue
//     border-radius: 100px
//     +poppins
//     +caption-2
//     transition: border-color .2s
//     +placeholder
//         color: $darkBlue
//     +dark
//         border-color: $darkBlue
//         color: $darkBlue
//     &:focus
//         border-color: $blue

