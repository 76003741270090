@import "../../../styles/helpers";

.options {
    display: flex;
    padding: 8px;
    border-radius: 32px;
    background: transparent;
    @include dark {
        background: transparent; } }

.button {
    &:not(:last-child) {
        margin-right: 24px; }
    &:hover {
        svg {
            fill: $neutrals8; } } }

.favorite {
    svg {
        fill: $pink; }
    &:hover {
        svg {
            fill: $pink; } } }
