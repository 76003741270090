@import "../../../styles/helpers";
.container {
    position: relative;
    border-radius: 15px;
    min-height: 350px;
    width: 38%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    @include m {
        width: 90%;
        margin-left: 5%;
        margin-top: 15%; } }

